import React from "react"
import {CssBaseline, ThemeProvider} from "@material-ui/core";
import theme from "../theme";
import {Helmet} from "react-helmet";
import logoImage from "../images/hca-logo.jpg";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import heroImage from "../images/homepage-hero-bg-d.jpg";
import heroImageMobile from "../images/homepage-hero-bg-m.jpg";
import heroImageTablet from "../images/homepage-hero-bg-t.jpg";

const useStyles = makeStyles(theme => ({
    bgImage: {
        position: "fixed",
        top: 0,
        width: "100%",
        height: "100%",
        backgroundImage: `url("${heroImage}")`,
        backgroundSize: "cover",
        "@media (max-width: 767px)": {
            backgroundImage: `url("${heroImageMobile}")`,
        },
        "@media (min-width: 768px) and (max-width: 1024px)": {
            backgroundImage: `url("${heroImageTablet}")`,
        }
    },
    section: {
        position: "relative",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "40px",
        color: "#F7F3ED"
    },
    title: {
        paddingTop: "110px",
        paddingBottom: "60px",
        textAlign: "center",
        "@media (max-width: 767px)": {
            fontSize: "36px",
            lineHeight: "46px",
            letterSpacing: "2px",
            paddingTop: "86px",
            paddingBottom: "30px",
        },
        "@media (min-width: 768px) and (max-width: 1024px)": {
            paddingTop: "188px",
            paddingBottom: "51px",
            width: "706px"
        }
    },
    description: {
        width: "480px",
        textAlign: "center",
        "@media (max-width: 767px)": {
            width: "305px"
        }
    },
    contactInfo: {
        position:"absolute",
        left: "57px",
        bottom:"66px",
        fontFamily: "FuturaBold",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16px",
        color: "#F7F3ED",
        textTransform: "uppercase"
    },
    address: {
        marginBottom: "20px",
        "@media (max-width: 768px)": {
            marginBottom: "15px",
        }
    },
    link: {
        textDecoration: "none",
        color: "#F7F3ED"
    }
}))

const NotFound = () => {
    const classes = useStyles()

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Helmet>
                <title>Hunter Creek Advisors, LLC</title>
                <meta name="description"
                      content="We are a family office providing investment management services across all asset classes and investment structures."/>
            </Helmet>
            <div className={classes.bgImage}>
                <section className={classes.section}>
                    <img src={logoImage} alt="logo-img" width="121" height="51"></img>
                    <Typography variant="h1" className={classes.title}>Page Not Found</Typography>
                    <div className={classes.description}>
                        <Typography variant="h3">The requested page was not found.</Typography>
                    </div>
                </section>

                <div className={classes.contactInfo}>
                    <div> 412 West 15th Street, 16th Floor</div>
                    <div className={classes.address}> New York, New York 10011</div>
                    <div><a href="tel:212-680-4600" className={classes.link}> (212) 680-4600</a></div>
                    <div><a href="mailto:info@huntercreekllc.com" className={classes.link}>info@huntercreekllc.com</a></div>
                </div>
            </div>
        </ThemeProvider>
    );
}

export default NotFound